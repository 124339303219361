import React from "react"
// import ImageReferences from "../components/imagereference"
import imageTyler from "../images/tylerp.png"
import imageDesmond from "../images/desmond.png"
import imageShawn from "../images/shawn.png"
import imageLuyu from "../images/luyu.png"
import imagePeter from "../images/peter.png"

const References = () => (
  <div className="References">
    <svg viewBox="0 0 1920 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1920 0H0L1.49922 180L1920 0Z" fill="white" />
    </svg>

    <svg
      viewBox="0 0 1920 180"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1920 180H0L0 0L1920 180Z" fill="#f6f6f6" />
    </svg>
    <div className="ReferencesGroup">
      <div className="column">
        <h2>What Others Have to Say</h2>
        <p>
          incredible greatful to have worked with each and every one of these
          brilliant minds
        </p>
      </div>

      <div className="column">
        <div className="wrapper">
          <input checked type="radio" name="slider" id="slide1" />
          <input type="radio" name="slider" id="slide2" />
          <input type="radio" name="slider" id="slide3" />
          <input type="radio" name="slider" id="slide4" />
          <input type="radio" name="slider" id="slide5" />

          <div className="slider-wrapper">
            <div className="inner">
              <article>
                <img
                  src={imageTyler}
                  width="200"
                  height="200"
                  alt="reference"
                />
                <div className="info bottom">
                  <h3>Tyler Payne</h3>
                  <h4>Senior Creative Director @ Unity</h4>
                  <p>
                    “Tyler is a standout when it comes to the modern creative
                    director. He possesses the rare ability to bridge the gap
                    between creator and manager. He has a true gift for crafting
                    elegant solutions for highly complex problems”
                  </p>
                </div>
              </article>
              <article>
                <img
                  src={imageDesmond}
                  width="200"
                  height="200"
                  alt="reference"
                />
                <div className="info bottom">
                  <h3>Desmond Lee</h3>
                  <h4>General Manager @ Unity</h4>
                  <p>
                    “Tyler is a talented Designer that focuses on the
                    end-user/customer when creating experiences and products.
                    Tyler's friendly and easy-going demeanor allows him to build
                    relationships quickly and facilitate candid conversations to
                    build trust and solve problems. He is open to different
                    approaches to bridge the gap between Design and Development
                    in order to execute on a product vision”
                  </p>
                </div>
              </article>
              <article>
                <img
                  src={imageShawn}
                  width="200"
                  height="200"
                  alt="reference"
                />
                <div className="info bottom">
                  <h3>Shawn Adrian</h3>
                  <h4>Founder + Designer @ Input Logic</h4>
                  <p>
                    “Tyler is a driven product developer, with a keen problem
                    solving ability. He's passionate about entrepreneurship and
                    gets things done.”
                  </p>
                </div>
              </article>
              <article>
                <img src={imageLuyu} width="200" height="200" alt="reference" />
                <div className="info bottom">
                  <h3>Lu Yu</h3>
                  <h4>UX Designer @ Tableau Software</h4>
                  <p>
                    “Tyler is a dedicated and driven UX design professional who
                    is capable of managing many complex projects simultaneously.
                    He is extremely organized and has a wide range of in-depth
                    knowledge on design relevant technology. I would like to
                    recommend Tyler because I can always count on him to provide
                    valuable feedback when I encounter a design challenge”
                  </p>
                </div>
              </article>
              <article>
                <img
                  src={imagePeter}
                  width="200"
                  height="200"
                  alt="reference"
                />
                <div className="info bottom">
                  <h3>Peter Komierowski</h3>
                  <h4>Partner @ Turtle Inc.</h4>
                  <p>
                    “Tyler was instrumental in laying the foundation of the
                    design process company-wide. I’ve watched him work
                    tirelessly to promote an agile methodology and the benefits
                    of user-centered design. He's an exceptional leader, being
                    able to manage multiple projects with multiple team members,
                    all while giving everyone ownership of their work. On top of
                    all this, Tyler is an accomplished visual designer,
                    well-versed in all the latest tools and trends, with the
                    ability to bust out 30+ high-fidelity screens in less than a
                    week.”
                  </p>
                </div>
              </article>
            </div>
          </div>

          <div className="slider-dot-control">
            <label for="slide1"></label>
            <label for="slide2"></label>
            <label for="slide3"></label>
            <label for="slide4"></label>
            <label for="slide5"></label>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default References
