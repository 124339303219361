import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Header from "../components/header"
import Hero from "../components/hero"
import Clients from "../components/clients"
import Vision from "../components/vision"
import References from "../components/references"
import Arlo from "../components/arlo"
import Netgear from "../components/netgear"
import Services from "../components/services"
import Contact from "../components/contact"

const IndexPage = ({ title }) => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <Clients />
    <Vision />
    <References />
    <Arlo />
    <Netgear />
    <Services />
    <Contact />
  </Layout>
)

export default IndexPage
