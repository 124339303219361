import PropTypes from "prop-types"
import React from "react"

const Hero = ({ siteTitle }) => (
  <div className="Hero">
    <div className="HeroGroup">
      <h1>Tyler Balaban</h1>
      <p>Creative Director + Product Designer</p>
      <div>
        <a href="#contact">contact</a>
        <a target="_blank" href="https://ca.linkedin.com/in/tylerbalaban">
          resumé
        </a>
      </div>
    </div>
  </div>
)

Hero.propTypes = {
  siteTitle: PropTypes.string,
}

Hero.defaultProps = {
  siteTitle: ``,
}

export default Hero
