import React from "react"

const Clients = () => (
  <div className="Client">
    <div className="ClientGroup">
      <h2>
        Over a <span>Decade</span> of Experience
      </h2>
      <p>
        passionate about technology • results driven • adaptive to any situation
      </p>
      <div className="ClientImage" />
    </div>
  </div>
)

export default Clients
