import React from "react"

const Contact = () => (
  <div className="Contact" id="contact">
    <div className="ContactGroup">
      <div className="column">
        <h2>Let's Chat!</h2>
        <p>
          have any questions, looking for a mentor, interested in working
          together or just want to connect, please feel free to reach out
        </p>
        <form
          action="https://getform.io/f/e8d8fbae-913c-49f3-8639-40b9f7a33bd9"
          method="POST"
        >
          <input
            type="text"
            name="firstname"
            placeholder="first name"
            required
          />
          <input type="text" name="lastname" placeholder="last name" required />
          <input
            type="email"
            name="email"
            placeholder="email@company.com"
            required
          />
          <input type="tel" name="tel" placeholder="403-111-1111" />
          <textarea
            type="text"
            name="description"
            placeholder="what would you like to chat about?"
          ></textarea>
          <button type="submit">get in touch</button>
        </form>
      </div>
    </div>
  </div>
)

export default Contact
