import React from "react"
import ImageServices from "../components/imageServices"

const Services = () => (
  <div className="Services">
    <h2>Services</h2>
    <div className="ServicesGroup">
      <div className="column">
        <h3>Creative Direction</h3>
        <ul>
          <li>Art Direction</li>
          <li>
            Process Development
            <ul>
              <li>Creative Process</li>
              <li>Design Systems</li>
            </ul>
          </li>
          <li>
            Team Development
            <ul>
              <li>Talent Acquisition</li>
              <li>Mentoring and Leadership</li>
              <li>Culture & Dynamics</li>
              <li>Onboarding & Training</li>
            </ul>
          </li>
        </ul>
      </div>

      <div className="column">
        <h3>User Interface Design (UI)</h3>
        <ul>
          <li>
            Product Design
            <ul>
              <li>Responsive Web</li>
              <li>Mobile Apps</li>
              <li>Desktop & Apple TV</li>
              <li>Smart Home & Wearables</li>
              <li>AR / VR / XR</li>
            </ul>
          </li>
          <li>
            Brand Development
            <ul>
              <li>Logo Design</li>
              <li>Brandbook</li>
            </ul>
          </li>
        </ul>
      </div>

      <div className="column">
        <h3>User Experience Design (UX)</h3>
        <ul>
          <li>
            Project Management
            <ul>
              <li>Product Owner</li>
              <li>Tailored Design Process</li>
            </ul>
          </li>
          <li>
            User Testing
            <ul>
              <li>Qualitative & Quantitative Data</li>
            </ul>
          </li>
          <li>User Intuition Design</li>
          <li>
            Core Values
            <ul>
              <li>User Stories</li>
              <li>Value Proposition Mapping</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <ImageServices />
  </div>
)

export default Services
