import React from "react"
import ImageNetgear from "../components/imagenetgear"

const Netgear = () => (
  <div className="Netgear">
    <ImageNetgear />
    <div className="NetgearGroup">
      <div className="column">
        <h3>lead user experience & digital product designer</h3>
        <p>
          responsible for the latest interface design and visual language of the
          Netgear app suite including mobile, tablet, desktop and apple tv
        </p>
      </div>
      <div className="column"></div>
    </div>
  </div>
)

export default Netgear
