import React from "react"
// import Image from "gatsby-image"
// import { useStaticQuery, graphql } from "gatsby"
import ImageVision from "../components/imagevision"

const Vision = ({ data }) => (
  <div className="Vision">
    <svg
      preserveAspectRatio="none"
      viewBox="0 0 1920 415"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H1920V235L0 415V0Z"
        fill="#EEEEEE"
      />
    </svg>

    <ImageVision />
    {/* <Image fluid={data.placeholderImage.childImageSharp.fluid} /> */}
    <div className="VisionGroup">
      <div className="column">
        <h2>
          <span>Communicate</span> the Vision
        </h2>
        <p>
          effectively tell the story by understanding core business objectives
          and leveraging extensive knowledge in emerging technologies
        </p>
      </div>
      <div className="column">
        <ul>
          <li>blockchain</li>
          <li>mobile</li>
          <li>hardware</li>
          <li>mixed reality</li>
          <li>augmented reality</li>
          <li>virtual reality</li>
          <li>robotics</li>
        </ul>
      </div>
    </div>
  </div>
)

export default Vision

// export const data = useStaticQuery(graphql`
//   query {
//     placeholderImage: file(relativePath: { eq: "vision.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 1920) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `)
