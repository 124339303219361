import React from "react"
import ImageArlo from "../components/imagearlo"

const Arlo = () => (
  <div className="Arlo">
    <svg viewBox="0 0 1920 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1920 180H0L0 0L1920 180Z" fill="#ffffff" />
    </svg>
    <div className="ArloGroup">
      <div className="column">
        <h2>Featured Work</h2>
      </div>
      <div className="column"></div>
    </div>

    <ImageArlo />
    <div className="ArloGroup">
      <div className="column"></div>
      <div className="column">
        <h3>lead user experience & digital product designer</h3>
        <p>
          responsible for the latest interface design and visual language of the
          arlo app suite including mobile, tablet, desktop and apple tv
        </p>
      </div>
    </div>
  </div>
)

export default Arlo
